<template>
  <v-card flat class="timeline-card-container">
    <timeline
      :initialStep="clickedTimelineStep"
      :collectionId="collectionId"
      :refreshTimeline="timelineDataChangeFromPrevious"
      @stepper-click="clickTimelineStep"
    />
  </v-card>
</template>
<script>
import Timeline from "@/components/Inquiry/Timeline.vue";
export default {
  name: "TimelineTab",
  components: {
    Timeline,
  },
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    clickedTimelineStep: {
      type: Number,
      required: true,
    },
    timelineDataChangeFromPrevious: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    clickTimelineStep(step) {
      this.$emit("stepper-click", step);
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-card-container {
  margin: 0px;
  margin-top: -10px;
  height: auto;
}
</style>
